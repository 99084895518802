export default class Renegociacao {
    constructor() {
        this.valor_de = null;
        this.valor_ate = null;
        this.desconto = null;
        this.desconto_porcentagem = null;
        this.cartao = false;
        this.juros_cartao = null;
        this.parcela_cartao = null;
        this.boleto = false;
        this.juros_boleto = null;
        this.parcela_boleto = null;
        this.status = false;
        this.data_inicio = null;
        this.data_fim = null;
        this.message = null;
    }
}
